<template>

    <div class="min-h-screen flex p-8 items-center justify-center login" v-cloak>
        <div class="flex flex-col clerk-login-wrapper">
            <div>
                <div class="flex justify-center">
                    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
                    <img v-if="terminal.colorscheme === 'dark'" class="h-12" src="../../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
                    <img v-else class="h-12" src="../../assets/img/logos/sjef_logo.png" alt="Sjef logo" />
                </div>
                <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold" :class="{'text-white': terminal.colorscheme === 'dark'}">
                    {{$t('translations.views.clerk.login.title')}}
                </h2>

                <div class="h-5">
                    <p class="text-red-500" v-if="invalid">{{$t('translations.views.clerk.login.invalid-credentials')}}</p>
                </div>
            </div>
            <form class="mt-8 text-lg" action="#" @submit.prevent="onSubmit()" autocomplete="off">
                <div class="rounded-sm default-input shadow-sm">

                    <div class="mt-2 mb-3">
                        <input placeholder="Pincode" readonly v-model="entered_pincode" name="terminal_pincode"
                               type="password" required class="@error('entered_pincode') border-red-500 @enderror" />
                        <div class="clear-field" @click="clear()" v-if="entered_pincode.length > 0">
                            +
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('1')" class="button button-blur button-wide">
                    1
                </a>
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('2')" class="button button-blur button-wide">
                    2
                </a>
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('3')" class="button button-blur button-wide">
                    3
                </a>
            </span>
                </div>

                <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('4')" class="button button-blur button-wide">
                    4
                </a>
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('5')" class="button button-blur button-wide">
                    5
                </a>
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('6')" class="button button-blur button-wide">
                    6
                </a>
            </span>
                </div>

                <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('7')" class="button button-blur button-wide">
                    7
                </a>
            </span>
                    <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('8')" class="button button-blur button-wide">
                    8
                </a>
            </span>
                    <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('9')" class="button button-blur button-wide">
                    9
                </a>
            </span>
                </div>

                <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('0')" class="button button-blur button-wide">
                    0
                </a>
            </span>
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                <a @click="clear()" class="button button-blur button-wide">
                    {{$t('translations.views.clerk.login.clear')}}
                </a>
            </span>
                </div>


                <div class="mt-6">
                    <button type="submit" class="button button-filled button-large button-wide">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg class="h-5 w-5 text-orange-200 group-hover:text-orange-100 transition ease-in-out duration-150"
                         fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clip-rule="evenodd" />
                    </svg>
                </span>
                {{$t('translations.views.clerk.login.sign-in')}}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <modal-time-registration v-if="useTimeregistration"/>

</template>

<script>
import axios from 'axios';

import BarcodeReader from '../../mixins/barcode.reader.js';
import ModalTimeRegistration from '../../components/ModalTimeRegistration.vue';

export default {
    name: 'ClerkLogin',
    mixins: [BarcodeReader],
    components:{
        ModalTimeRegistration
    },
    data () {
        return {
            invalid: false,
            entered_pincode: '',
        };
    },
    async mounted () {
        this.entered_pincode = '';
    },

    methods: {
        setInput (value) {
            this.invalid = false;
            this.entered_pincode += value;
        },

        clear () {
            this.entered_pincode = '';
        },

        barcodeScanned(barcode) {
            this.login({
                'token': barcode
            });
        },

        onSubmit() {
            if (this.entered_pincode == '') {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Voer pincode in...",
                }, 2000);
                return;
            }

            this.login({
                'pincode': this.entered_pincode
            });
        },

        async login (data) {
            axios.post('/clerks/login', data).then(response => {

                if (response.data.result === 'failed') {
                    this.invalid = true;
                    this.entered_pincode = '';
                    return;
                }

                this.$store.dispatch('transaction/setClerk', response.data.data);

                if (this.terminal.layout === 'mobileordering') {
                    this.$router.push({ name: 'mobilepos.areas' });
                } else {
                    this.$router.push({ name: 'pos' });
                }

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);

            });
        },
    },
    computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
        useTimeregistration() {
            return this.terminal.clerks_timeregistrations;
        }
    },
};
</script>
