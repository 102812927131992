<template>
    <!-- CSS Style should be placed in the right place -->
    <div class="timeregistration" style="position:absolute;top: 0;right:0">
        <button class="button-filled  p-3 m-2 rounded" @click="open('clockin')">{{ $t('translations.views.timeregistration.clockin') }}</button>
        <button class="btn btn-primary p-3 m-2 rounded" @click="open('clockout')">{{ $t('translations.views.timeregistration.clockout') }}</button>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="modal-content">

                <modal-close @close="close()"/>

                <div class="flex justify-between items-center mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                <div v-if="mode === 'clockin'">{{ $t('translations.views.timeregistration.modal.clockintitle') }}</div>
                                <div v-if="mode === 'clockout'">{{ $t('translations.views.timeregistration.modal.clockouttitle') }}</div>
                            </h3>
                        </div>
                    </div>

                    <div class="h-5">
                        <p class="text-red-500" v-if="invalid">{{$t('translations.views.clerk.login.invalid-credentials')}}</p>
                    </div>

                    <form class="mt-8 text-lg" action="#" @submit.prevent="onSubmit()" autocomplete="off">
                        <div class="rounded-sm default-input shadow-sm">

                            <div class="mt-2 mb-3">
                                <input :placeholder="$t('translations.views.timeregistration.modal.inputplaceholder')" readonly v-model="pincode" name="terminal_pincode"
                                    type="password" required class="@error('pincode') border-red-500 @enderror" />
                                <div class="clear-field" @click="clear()" v-if="pincode.length > 0">
                                    +
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-3 mt-2 gap-2">
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('1')" class="button button-blur button-wide">
                            1
                        </a>
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('2')" class="button button-blur button-wide">
                            2
                        </a>
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('3')" class="button button-blur button-wide">
                            3
                        </a>
                    </span>
                        </div>

                        <div class="grid grid-cols-3 mt-2 gap-2">
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('4')" class="button button-blur button-wide">
                            4
                        </a>
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('5')" class="button button-blur button-wide">
                            5
                        </a>
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('6')" class="button button-blur button-wide">
                            6
                        </a>
                    </span>
                        </div>  

                        <div class="grid grid-cols-3 mt-2 gap-2">
                    <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('7')" class="button button-blur button-wide">
                            7
                        </a>
                    </span>
                            <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('8')" class="button button-blur button-wide">
                            8
                        </a>
                    </span>
                            <span class="h-15 key col-span-1 inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('9')" class="button button-blur button-wide">
                            9
                        </a>
                    </span>
                        </div>

                        <div class="grid grid-cols-3 mt-2 gap-2">
                    <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="setInput('0')" class="button button-blur button-wide">
                            0
                        </a>
                    </span>
                            <span class="h-15 key inline-flex rounded-sm  shadow-sm">
                        <a @click="clear()" class="button button-blur button-wide">
                            {{ $t('translations.views.timeregistration.modal.clear') }}
                        </a>
                    </span>
                        </div>


                        <div class="mt-6">
                            <button type="submit" class="button button-filled button-large button-wide">
                                <!-- <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg class="h-5 w-5 text-orange-200 group-hover:text-orange-100 transition ease-in-out duration-150"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </span> -->
                                {{ $t('translations.views.timeregistration.modal.confirm') }}
                            </button>
                        </div>
                    </form>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// components 
import ModalClose from "./ModalClose";

export default {
    components:{
        ModalClose
    },
    data () {
        return {
            active: false,
            invalid: false,
            pincode: '',
            mode: '',
        }
    },
    methods: {
        open(mode) {
            this.mode = mode
            this.active = true
        },
        close() {
            this.active = false
            this.pincode = '';
            this.invalid = false;
        },
        clear () {
            this.invalid = false;
            this.pincode = '';
        },
        setInput (value) {
            this.invalid = false;
            this.pincode += value;
        },
        onSubmit() {
            if(this.mode == 'clockin'){
                this.clockIn();
            }
            else if(this.mode == 'clockout'){
                this.clockOut();
            }
        },
        async clockIn(){
            try{
                const response = await axios.post('/clerks/clockin', {
                    'pincode': this.pincode
                })

                if (response.data.result === 'failed') {
                    this.invalid = true;
                    this.pincode = '';
                    return;
                }

                this.active = false;
                this.pincode = '';

                this.$router.push({
                    name: 'clerk.login'
                })

                if (response.data.error === 'clockinerror') {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.clockinerrortitle,
                        text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.clockinerrortext,
                    }, 2000);
                    return;
                }

                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.successmessages.clockinsuccesstitle,
                    text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.successmessages.clockinsuccesstext,
                }, 2000);

            }
            catch(e){
                this.$notify({
                group: "notifications",
                type: 'error',
                title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.errortitle,
                text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.errortext,
            }, 2000);
            }

        },
        async clockOut(){
            try{
                const response = await axios.post('/clerks/clockout', {
                    'pincode': this.pincode
                })

                if (response.data.result === 'failed') {
                    this.invalid = true;
                    this.pincode = '';
                    return;
                }

                this.active = false;
                this.pincode = '';

                this.$router.push({
                    name: 'clerk.login'
                })

                if (response.data.error === 'clockouterror') {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.clockouterrortitle,
                        text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.clockouterrortext,
                    }, 2000);
                    return;
                }

                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.successmessages.clockoutsuccesstitle,
                    text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.successmessages.clockoutsuccesstext,
                }, 2000);
            }
            catch(e){
                this.$notify({
                group: "notifications",
                type: 'error',
                title: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.errortitle,
                text: this.$i18n.messages[this.$i18n.locale].translations.views.timeregistration.errormessages.errortext,
            }, 2000);
            }
        }
    }
}
</script>